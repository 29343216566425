import Action from './components/Action'
import Button from './components/Button'
import ButtonGroup from './components/ButtonGroup'
import Card from './components/Card'
import CardLoading from './components/CardLoading'
import CheckboxOption from './components/CheckboxOption'
import Collapsible from './components/Collapsible'
import Icon from './components/Icon'
import Input from './components/Input'
import InputError from './components/InputError'
import Modal from './components/Modal'
import NavBar from './components/NavBar'
import NavFooter from './components/NavFooter'
import Pagination from './components/Pagination'
import RadioOption from './components/RadioOption'
import Select from './components/Select'
import Spinner from './components/Spinner'
import Textarea from './components/Textarea'
import Toggle from './components/Toggle'
import { Box, Inline } from './components/Box'
import {
  H1,
  H1Alt,
  H2,
  H2Alt,
  H3,
  H3Alt,
  H4,
  H5,
  H6,
  P,
  SM,
  XS
} from './components/Typography'
import { themeConWeb, themeEnterprise, themeGO } from './themes.js'

// export all the React components, these are exposed via NPM package
export {
  Action,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardLoading,
  CheckboxOption,
  Collapsible,
  H1,
  H1Alt,
  H2,
  H2Alt,
  H3,
  H3Alt,
  H4,
  H5,
  H6,
  Icon,
  Inline,
  Input,
  InputError,
  Modal,
  NavBar,
  NavFooter,
  P,
  Pagination,
  RadioOption,
  SM,
  Select,
  Spinner,
  Textarea,
  Toggle,
  themeConWeb,
  themeEnterprise,
  themeGO,
  XS
}
