import React from 'react'
import { createUltimatePagination } from 'react-ultimate-pagination'
import styled from 'styled-components'

const StyledButton = styled.button`
  color: white;
  border-radius: 0;
  user-select: none;
  white-space: normal;
  height: auto;
  color: ${props => props.theme.color.brandPrimary};
  position: relative;
  min-height: 38px;
  margin: 0;
  padding: 10px var(--gutter) 8px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 2px;
  text-decoration: none;
  cursor: pointer;
  background-color: white;
  transition: background-color ${props => props.theme.transition},
    color ${props => props.theme.transition};
  overflow: visible;
  user-select: none;
  border: none;
  border-right: 1px solid lightgrey;
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  flex-grow: 1;

  &:hover,
  &:focus {
    background-color: ${props => props.theme.color.brandPrimary};
    color: white;
    filter: brightness(120%);
    border: none;
    border-right: 1px solid lightgrey;
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
  }
  &:first-child {
    border-radius: ${props => props.theme.borderRadius} 0 0
      ${props => props.theme.borderRadius};
    border-left: 1px solid lightgrey;
  }
  &:last-child {
    border-radius: 0 ${props => props.theme.borderRadius}
      ${props => props.theme.borderRadius} 0;
  }

  &.active {
    background-color: ${props => props.theme.color.brandPrimary};
    color: white;
    &:hover,
    &:focus {
      background-color: ${props => props.theme.color.brandPrimary};
      color: white;
      filter: brightness(100%);
    }
  }
`

const PageButton = ({ children, ...rest }) => (
  <StyledButton {...rest}>{children}</StyledButton>
)

const Page = ({ onClick, isActive, value, ...rest }) => (
  <PageButton onClick={onClick} className={isActive ? 'active' : ''} {...rest}>
    {value}
  </PageButton>
)

const Ellipsis = ({ onClick, ...rest }) => (
  <PageButton onClick={onClick} {...rest}>
    ...
  </PageButton>
)

const FirstPageLink = ({ onClick, ...rest }) => (
  <PageButton onClick={onClick} {...rest}>
    &lt;&lt;
  </PageButton>
)

const PreviousPageLink = ({ onClick, ...rest }) => (
  <PageButton onClick={onClick} {...rest}>
    &lt;
  </PageButton>
)

const NextPageLink = ({ onClick, ...rest }) => (
  <PageButton onClick={onClick} {...rest}>
    &gt;
  </PageButton>
)

const LastPageLink = ({ onClick, ...rest }) => (
  <PageButton onClick={onClick} {...rest}>
    &gt;&gt;
  </PageButton>
)

const PaginationWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 600px;
  margin: 0 auto;
`

const Wrapper = ({ children, ...rest }) => (
  <PaginationWrapper className="pagination" {...rest}>
    {children}
  </PaginationWrapper>
)

export default createUltimatePagination({
  itemTypeToComponent: {
    PAGE: Page,
    ELLIPSIS: Ellipsis,
    FIRST_PAGE_LINK: FirstPageLink,
    PREVIOUS_PAGE_LINK: PreviousPageLink,
    NEXT_PAGE_LINK: NextPageLink,
    LAST_PAGE_LINK: LastPageLink
  },
  WrapperComponent: Wrapper
})
